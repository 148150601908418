import React, { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "../../components/rating";
import { IData, IRating } from "../../interfaces";
import { Spinner } from "../../components/spinner";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import "./index.css";



interface IProps {
  data: IData;
  onModalAccept: () => unknown;
}

const customStyles = {
  overlay: {
    backgroundColor: "rgb(0 0 0 / 93%)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 16
  },
};

export const Home: FC<IProps> = ({ data, onModalAccept }) => {
  const [isOpen, setIsOpen] = React.useState(data?.modal?.visible ?? false);

  function closeModal(accept: boolean) {
    if (accept) onModalAccept();
    setIsOpen(false);
  }

  return (
      <Container>
        <Row>
          <Col>
            {!!data.text && <p className="mb-5">{data.text}</p>}
            {data.ratings.length ? (
                <div>
                  {(data.ratings as IRating[]).map((rating, index) => (
                      <Rating
                          data={data}
                          key={index}
                          rating={rating}
                          number={index + 1}
                      />
                  ))}
                </div>
            ) : (
                <Spinner/>
            )}
          </Col>
        </Row>
        {!!data?.modal && <Modal isOpen={isOpen} style={customStyles}>
          <div className="modalBody">
            {!!data.modal.name && <span>{data.modal.name}</span>}
            <div className="modal_buttons">
              {!!data.modal.button_text_no && <Button
                  className="rating__button"
                  variant="danger"
                  onClick={() => closeModal(false)}
              >
                {data.modal.button_text_no}
              </Button>}
              {!!data.modal.button_text && <Button
                  className="rating__button"
                  variant="success"
                  onClick={() => closeModal(true)}
              >
                {data.modal.button_text}
              </Button>}
            </div>
          </div>
        </Modal>}
      </Container>

  );
};
